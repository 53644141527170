export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        positions :'', 
        position :'', 
    },
    
    getters : {
        positions: (state )=> state.positions,
        position: (state )=> state.position,
       
    },


    mutations : { 
        setpositions :(state,positions ) => (state.positions = positions), 
        setposition :(state,position ) => (state.position = position), 
    },

    actions : {

        async create_position( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_position')
            return new Promise((resolve, reject) => {
            axios.post("/admin/roles/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpositions', response.data.res)
                toast.success("Position added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_positions( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_positions')
            return new Promise((resolve, reject) => {
            axios.post("/admin/roles", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpositions', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_position( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_position' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/roles/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpositions', response.data.res)
                toast.success("Position updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_position( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_position' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/roles/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpositions', response.data.res)
                toast.success("Position deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



    },

    
}














export const namespaced = true 
import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
    state : {
        requisitions :[], 
        requisition :'',
        workplan_budgets :'', 
    },
    
    getters : {
        requisitions: (state )=> state.requisitions,
        requisition: (state )=> state.requisition,
        workplan_budgets: (state )=> state.workplan_budgets,
    },


    mutations : { 
        setrequisitions :(state,requisitions ) => (state.requisitions = requisitions), 
        setrequisition :(state,requisition ) => (state.requisition = requisition), 
        setworkplan_budgets :(state,workplan_budgets ) => (state.workplan_budgets = workplan_budgets),
        autoupdaterequisition: (state, requisition) => {
            state.requisitions.data.push(requisition.requisition, 1)
        },
    },

    actions : {

        async create_requisition( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_requisition')
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisition', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        

        async update_requisition( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_requisition')
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisition', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_requisition( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_requisition' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisition', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_requisitions( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_requisitions')
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisitions', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_history_requisitions( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_requisitions')
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/history?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisitions', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        autoupdaterequisition( { dispatch, commit, getters }, requisition) {
            // if (getters.authuser.role.restrictions.filter((res)=>res.slug == 'requisition_approval_one').length != 0) {
            //     dispatch('get_approval_requisitions')
            //     toast.success('You have a new requisition that needs approval')
            // }else if (getters.authuser.role.restrictions.filter((res)=>res.slug == 'requisition_approval_two').length != 0){
            //     dispatch('get_approval_requisitions')
            //     toast.success('You have a new requisition that needs approval')
            // }else if(getters.authuser.role.restrictions.filter((res)=>res.slug == 'requisition_approval_three').length != 0){
            //     dispatch('get_approval_requisitions')
            //     toast.success('You have a new requisition that needs approval')
            // }

        },

        async get_approval_requisitions( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_approval_requisitions')
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/approval?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisitions', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_requisition( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_requisition' +id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    commit('setrequisitions', response.data.res)
                    toast.success('Requisition Deleted Successfully')
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            toast.error(error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_workplan_budgets( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_workplan_budgets')
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/requisition_workplan_budgets?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan_budgets', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async get_requistion_requestable_incomes( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_requistion_requestable_incomes')
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/requestable_incomes", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdeposits', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async create_requisition_item( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_requisition_item')
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/items/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisition', response.data.res)
                toast.success(response.data.msg)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_requisition_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_requisition_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/items/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisition', response.data.res)
                toast.success(response.data.msg)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_requisition_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_requisition_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/items/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisition', response.data.res)
                toast.success(response.data.msg)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async submit_requisition_for_approval( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'submit_requisition_for_approval' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisition', response.data.res)
                toast.success(response.data.msg)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async pay_out_requisition( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'pay_out_requisition' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/requisitions/payoutrequisition/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequisition', response.data.res)
                toast.success(response.data.msg)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    },

    
}














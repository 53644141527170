<template>
  
  <router-view></router-view>
</template>
<script>
import { mapActions , mapGetters} from 'vuex'
export default {
  

  methods:{
    ...mapActions(['getuseripaddress','getuserlocation'])
  },

  mounted(){
      var scripts = [
        
      ];
      scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
      })
  },


  created(){
    this.getuseripaddress().then(()=>{
      this.getuserlocation();
    })
  }
}
</script>


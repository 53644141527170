<template>
    <button v-if="loadingvalue != '' && loadingvalue === loading" class="btn btn-lg btn-primary " type="button" disabled>
        <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
        {{ loadingTxt }}
    </button>

    <button v-else type="submit"  :class="{'running ': loading == 'create_task'}" class="btn btn-lg btn-primary px-md-4 mt-lg-1 ld-ext-right">
        <span class="ps-1" v-if="loadingvalue != '' && loadingvalue === loading">{{  loadingTxt  }}</span>
        <span class="ps-1" v-else>{{  btnText  }}</span>
        <div style="color: aliceblue !important;" class="ld ld-ring ld-spin" ></div> 
    </button>

    </template>
    <script>
    import { mapGetters } from 'vuex'
    export default {
    
        props: {
            
            loadingvalue: {
                type: [String, Boolean, Number],
                default: ''
            },
            loadingTxt: {
                type: String,
                default: 'Loading...'
            },
            className: {
                type: String,
                default: 'btn-primary'
            },
            btnText: {
                type: String,
                default: 'Submit'
            },
            iconData: {
                type: String,
                default: ''
            }
        },
    
        computed:{
            ...mapGetters(['loading'])
        },
    
        methods: {
            handleClick() {
                if(!this.loading) {
                this.onClick()
                }
            }
        },
    }
    </script>
export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        sharedfolders:'',
        folders :'', 
        folder :'', 
    },
    
    getters : {
        sharedfolders: (state )=> state.sharedfolders,
        folders: (state )=> state.folders,
        folder: (state )=> state.folder,
       
    },


    mutations : { 
        setsharedfolders :(state,sharedfolders ) => (state.sharedfolders = sharedfolders), 
        setfolders :(state,folders ) => (state.folders = folders), 
        setfolder :(state,folder ) => (state.folder = folder), 
        
    },

    actions : {

        async create_folder( { commit }, data, ) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_folder')
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders/create", data, {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfolders', response.data.res)
                commit('setuploadPercentage', 0 )
                toast.success("New folder added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            commit('setuploadPercentage', 0 )
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_folders( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_folders')
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfolders', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_shared_folders( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_folders')
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders/shared", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsharedfolders', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        

        async get_all_folders( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_folders')
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders/all", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfolders', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_folder_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_folder_details' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfolder', response.data.res)
            
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        


        async update_folder( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_folder' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfolders', response.data.res)
                toast.success("folder updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_updloaded_file( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_folder' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders/get_file/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        async update_folder_file( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_folder_file')
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders/update_file/"+ id, data, {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfolders', response.data.res)
                commit('setuploadPercentage', 0 )
                toast.success("folder updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async change_folder_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_folder_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfolders', response.data.res)
                toast.success("folder Status Updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_folder( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_folder' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/folders/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfolders', response.data.res)
                toast.success("Folder deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}














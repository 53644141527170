export const namespaced = true 
import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
    state : {
        storerequests :[], 
        storerequest :'',
    },
    
    getters : {
        storerequests: (state )=> state.storerequests,
        storerequest: (state )=> state.storerequest,
    },


    mutations : { 
        setstorerequests :(state,storerequests ) => (state.storerequests = storerequests), 
        setstorerequest :(state,storerequest ) => (state.storerequest = storerequest), 
    },

    actions : {

        async create_storerequest( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_storerequest')
            return new Promise((resolve, reject) => {
            axios.post("/admin/storerequests/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstorerequest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        

        async update_storerequest( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_storerequest')
            return new Promise((resolve, reject) => {
            axios.post("/admin/storerequests/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstorerequest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_storerequest( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_storerequest' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/storerequests/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstorerequest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_storerequests( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_storerequests')
            return new Promise((resolve, reject) => {
            axios.post("/admin/storerequests?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstorerequests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    async get_storerequests_approval( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_storerequests')
            return new Promise((resolve, reject) => {
            axios.post("/admin/storerequests/approval?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstorerequests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_storerequest( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_storerequest' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/storerequests/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstorerequests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async submit_storerequest_for_approval( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'submit_storerequest_for_approval' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/storerequests/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstorerequest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },  
        
        async approve_storerequest( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'approve_storerequest' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/storerequests/approve/"+ id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setstorerequest', response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        ////items
                async create_storerequest_item( { commit }, data) {
                    commit('seterror', '')
                    commit('setmsg', '')
                    commit('setloader', 'create_storerequest')
                    return new Promise((resolve, reject) => {
                    axios.post("/admin/storerequests/items/create", data)
                    .then(response => {	
        
                        commit('setloader', false)
                        if(response.data){
                            commit('setstorerequest', response.data.res)
                        }
                        resolve(response)
                        
                    })
                    .catch(error=> {
                        commit('setloader', false)
                        if (error.response) {
                            if(error.response.data){
                                if(error.response.data.msg){
                                    commit('seterror', error.response.data.msg)
                                }else if(error.response.data.message){
                                    commit('seterrors', error.response.data.message)
                                }
                                
                            }
                        }
                        reject(error)
                    });
                    });	       
                },
        
                async delete_storerequest_item( { commit }, {data, id}) {
                    commit('seterror', '')
                    commit('setmsg', '')
                    commit('setloader', 'delete_storerequest' + id)
                    return new Promise((resolve, reject) => {
                    axios.post("/admin/storerequests/items/delete/"+ id, data)
                    .then(response => {	
        
                        commit('setloader', false)
                        if(response.data){
                            commit('setstorerequest', response.data.res)
                        }
                        resolve(response)
                        
                    })
                    .catch(error=> {
                        commit('setloader', false)
                        if (error.response) {
                            if(error.response.data){
                                if(error.response.data.msg){
                                    commit('seterror', error.response.data.msg)
                                }else if(error.response.data.message){
                                    commit('seterrors', error.response.data.message)
                                }
                                
                            }
                        }
                        reject(error)
                    });
                    });	       
                },

    },

    
}














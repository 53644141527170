import store from '@/store'

const adminguard = (to, from, next) => {
    if(localStorage.getItem('systemauthtoken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.name ){
        return next()
    }else{
        return next({ name: 'login' })
    }
};


let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/system/dash.vue"),
        title: '',
          children: [
            {
              path: "",
              name: "welcome",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/system/welcome/home.vue"),
              meta: {
              title: ' Welcome to You Dashboard'
              }
            },

            //staff & employees

            {
                path: "human-resource",
                name: "hrdash",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/system/structure/dash.vue"),
                meta: {
                title: 'Manage Staff & Employees'
                }
            },
            {
                path: "staff",
                name: "managestaff",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/system/staff/main.vue"),
                children: [
                    {
                        path: "",
                        name: "employees",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/system/staff/stafflist.vue"),
                        meta: {
                        title: 'Manage Employees'
                        }
                    },

                    {
                        path: "admin_list",
                        name: "adminlist",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/system/staff/adminlist.vue"),
                        meta: {
                        title: 'Manage Admin Employees'
                        }
                    },

                    {
                        path: "create_employee",
                        name: "create_employee",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/system/staff/createstaff.vue"),
                        meta: {
                        title: 'Create New Employee'
                        }
                    },

                    {
                        path: ":id",
                        name: "update_employee",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/system/staff/updatestaff.vue"),
                        meta: {
                        title: 'Update Employees'
                        }
                    },
                ],
            },



            //structure
            {
                path: "departments",
                name: "managedeparts",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/structure/departments.vue"),
                meta: {
                title: 'Manage Office Departments'
                }
            },

            {
                path: "positions",
                name: "managepositions",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/structure/positions.vue"),
                meta: {
                title: 'Manage Office Positions'
                }
            },

            {
                path: "permisions",
                name: "managepermisions",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/structure/permisions.vue"),
                meta: {
                title: 'Manage Office Permisions'
                }
            },




            //workplans
            {
                path: "workplans-&-budgets",
                name: "workplans&budgets",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/workplans/dash.vue"),
                meta: {
                title: 'Manage Office Workplans & Budgets'
                }
            },
            {
                path: "workplans",
                name: "manageworkplans",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/workplans/workplans.vue"),
                meta: {
                title: 'Manage Office Workplans'
                }
            },



            {
                path: "workplans/:id",
                name: "workplandetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/workplans/workplandetails.vue"),
                meta: {
                title: 'Workplan Details'
                }
            },

            {
                path: "workplans/budgets/:id",
                name: "workplanbudget_details",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/workplans/budgetdetails.vue"),
                meta: {
                title: 'Workplan Details'
                }
            },





            //requisitions

            {
                path: "requisitions",
                name: "requisitionsdashboard",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/requisitions/dash.vue"),
                meta: {
                title: 'Manage  Requisitions'
                }
            },
            {
                path: "requisitions/user",
                name: "userrequisitions",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/requisitions/userrequisitions.vue"),
                meta: {
                title: 'Manage My Requisitions'
                }
            },



            {
                path: "requisitions/:id",
                name: "requistiondetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/requisitions/requistiondetails.vue"),
                meta: {
                title: 'Requisitions Details'
                }
            },


            {
                path: "requisitions/history",
                name: "requisition_history",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/requisitions/requisition_history.vue"),
                meta: {
                title: 'Requisitions History'
                }
            },
             //petty cash
             {
                path: "petty_cash/user",
                name: "pettycashrequisitions",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/requisitions/pettycash/pettycashrequisitions.vue"),
                meta: {
                title: 'Manage Petty Cash Requisitions'
                }
            },



            {
                path: "petty_cash/:id",
                name: "pettycashrequistiondetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/requisitions/pettycash/pettycashrequistiondetails.vue"),
                meta: {
                title: 'Requisitions Details'
                }
            },


            //my profile
            {
                path: "/profile/",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/system/profile/main.vue"),
                children: [
                    {
                      path: "update",
                      name: "updateprofile",
                      props:true,
                      component: () => import("@/views/system/profile/profile.vue"),
                      meta: {
                      title: 'Update Your Profile'
                      }
                    },

                    {
                        path: "security",
                        name: "updatesecuritysettings",
                        props:true,
                        component: () => import("@/views/system/profile/security.vue"),
                        meta: {
                        title: 'Update Your Secuity Settings'
                        }
                    },

                    {
                        path: "attendence_history",
                        name: "attendencehistory",
                        props:true,
                        component: () => import("@/views/system/profile/attendence.vue"),
                        meta: {
                        title: 'My Attendence History'
                        }
                    },


                    {
                        path: "leave_history",
                        name: "leavehistory",
                        props:true,
                        component: () => import("@/views/system/profile/leavehistory.vue"),
                        meta: {
                        title: 'My Leave History'
                        }
                    },


                    {
                        path: "meals_history",
                        name: "mealshistory",
                        props:true,
                        component: () => import("@/views/system/profile/mealshistory.vue"),
                        meta: {
                        title: 'My Meals History'
                        }
                    },


                    
                ]
            },


            ///approvals

            {
                path: "approvals",
                name: "approvaldash",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/dash.vue"),
                meta: {
                title: 'Approval Dashboard'
                }
            },

            {
                path: "approvals/lpos",
                name: "approvalslpos",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/lpos/lpos.vue"),
                meta: {
                title: 'Manage LPOs'
                }
            },

            {
                path: "approvals/lpos/:id",
                name: "approvallpodetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/lpos/lpo_details.vue"),
                meta: {
                title: 'Manage LPO Details'
                }
            },


            //cash requisitions
            {
                path: "approvals/requisitions",
                name: "requisitionapprovals",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/requisitions/requisitions.vue"),
                meta: {
                title: 'Manage Requisition Approvals'
                }
            },

            {
                path: "approvals/requisitions/:id",
                name: "requistionapprovaldetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/requisitions/requisitiondetails.vue"),
                meta: {
                title: 'Requisition Approval Details'
                }
            },
            {
                path: "approvals/print/requisitions/:id",
                name: "printrequisition",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/requisitions/printrequisition.vue"),
                meta: {
                title: 'Print Requisition  Details'
                }
            },

            {
                path: "approvals/workplans",
                name: "workplanapprovals",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/workplans/workplans.vue"),
                meta: {
                title: 'Manage Workplan Approvals'
                }
            },

            {
                path: "approvals/workplans/:id",
                name: "workplanapprovaldetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/workplans/workplandetails.vue"),
                meta: {
                title: 'Workplan Approval Details'
                }
            },

            //store requisitions
            {
                path: "approvals/store-requisitions",
                name: "store_request_approvals",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/stores/storerequests.vue"),
                meta: {
                title: 'Manage Store Requisition Approvals'
                }
            },

            {
                path: "approvals/store-requisitions/:id",
                name: "store_approval_requsition_details",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/stores/storerequestdetails.vue"),
                meta: {
                title: 'Manage Store Requisition Approvals'
                }
            },

            {
                path: "approvals/staff-leave",
                name: "staffleaveapprovals",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/staffleave.vue"),
                meta: {
                title: 'Manage Requisition Approvals'
                }
            },

            {
                path: "approvals/salary-advance",
                name: "salaryadvanceapprovals",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/approvals/salaryadvance.vue"),
                meta: {
                title: 'Manage Requisition Approvals'
                }
            },



            ////accounts
            {
                path: "accounts",
                name: "accountsdashboard",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/accounts/dash.vue"),
                meta: {
                title: 'Manage Accounts'
                }
            },
            {
                path: "accounts/expenses",
                name: "accountsexpenses",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/accounts/expenses.vue"),
                meta: {
                title: 'Manage Accounts Expenses'
                }
            },

            {
                path: "accounts/deposits",
                name: "accountsdeposits",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/accounts/deposits.vue"),
                meta: {
                title: 'Manage Accounts Deposits'
                }
            },

            {
                path: "accounts/deposits/:id",
                name: "depositsdetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/accounts/depositsdetails.vue"),
                meta: {
                title: 'Manage Deposit Details'
                }
            },

            {
                path: "accounts/lpos",
                name: "accountslpos",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/accounts/lpos/lpos.vue"),
                meta: {
                title: 'Manage LPOs'
                }
            },

            {
                path: "accounts/lpos/:id",
                name: "accountslpodetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/accounts/lpos/lpo_details.vue"),
                meta: {
                title: 'Manage LPO Details'
                }
            },



            // stores 
            {
                path: "workshop-and-stores",
                name: "workshopstoresdash",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/system/store/dash.vue"),
                meta: {
                title: 'Manage Workshop and Stores'
                }
            },

            {
                path: "workshop",
                name: "workshopentries",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/system/store/workshop/workshopentries.vue"),
                meta: {
                title: 'Manage Workshop Entries'
                }
            },

            {
                path: "workshop/:id",
                name: "workshopentrydetails",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/system/store/workshop/workshopentrydetails.vue"),
                meta: {
                title: 'Manage Workshop Entries'
                }
            },

            {
                path: "stores/store-items",
                name: "storeitems",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/system/store/stores.vue"),
                meta: {
                title: 'Store Items'
                }
            },

            {
                path: "stores/store-entries",
                name: "storeentries",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/store/entries.vue"),
                meta: {
                title: 'Store Entries'
                }
            },

            {
                path: "stores/store-items/:id",
                name: "stores_item_details",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/store/stores_item_details.vue"),
                meta: {
                title: 'Store Entries'
                }
            },

            {
                path: "stores/store-requsitions",
                name: "store_requsitions",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/store/requisitions/requsitions.vue"),
                meta: {
                title: 'Store Entries'
                }
            },

            {
                path: "stores/store-requsitions/:id",
                name: "store_requsition_details",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/store/requisitions/requsition_details.vue"),
                meta: {
                title: 'Requisition Details'
                }
            },

            //staff forms
            {
                path: "attendances",
                name: "attendances",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/staffforms/attendence.vue"),
                meta: {
                title: 'Attendances'
                }
            },

            {
                path: "salary-advance",
                name: "salaryadvance",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/staffforms/salaryadvance.vue"),
                meta: {
                title: 'Salary Advance'
                }
            },

            {
                path: "staff-leave",
                name: "staffleave",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/staffforms/staffleave.vue"),
                meta: {
                title: 'Staff Leave'
                }
            },




            //resources
            {
                path: "records/mine",
                name: "myrecords",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/records/myrecords.vue"),
                meta: {
                title: 'Manage Shared Records'
                }
            },

            {
                path: "records/shared",
                name: "sharedrecords",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/records/sharedrecords.vue"),
                meta: {
                title: 'Manage Shared Records'
                }
            },


            {
                path: "records/all",
                name: "allrecords",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/records/allrecords.vue"),
                meta: {
                title: 'Manage All Records'
                }
            },

            {
                path: "records/folder/:id",
                name: "folderdetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/records/folderdetails.vue"),
                meta: {
                title: 'Manage All Records'
                }
            },

            {
                path: "records/folder/document/:id",
                name: "filedetails",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/records/fileviewer.vue"),
                meta: {
                title: 'View File Details'
                }
            },




            //reports
            
            {
                path: "reports/deposits",
                name: "depositsreport",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/reports/deposits.vue"),
                meta: {
                title: 'Deposits Resport'
                }
            },

            {
                path: "reports/expenses",
                name: "expensesreport",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/reports/expenses.vue"),
                meta: {
                title: 'Expenses Resport'
                }
            },


            {
                path: "reports/general_ledger",
                name: "general_ledgerreport",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/reports/generalledger.vue"),
                meta: {
                title: 'General Ledger Resport'
                }
            },


            {
                path: "reports/stores",
                name: "stores_report",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/reports/stores_report.vue"),
                meta: {
                title: 'General Stores Resport'
                }
            },


            {
                path: "reports/stores/:id",
                name: "stores_report_details",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/reports/stores_report_details.vue"),
                meta: {
                title: 'General Stores Resport'
                }
            },

            //workplans
            {
                path: "reports/workplans",
                name: "workplans_report",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/reports/workplans/workplans.vue"),
                meta: {
                title: 'Wokplan Resports'
                }
            },


            {
                path: "reports/workplans/:id",
                name: "workplans_report_details",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/reports/workplans/workplans_details.vue"),
                meta: {
                title: 'Wokplan Resports'
                }
            },


            {
                path: "procurement",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/procurement/main.vue"),
            
                children:[
                    {
                        path: "",
                        name: "procurement_dash",
                        props:true,
                        component: () => import("@/views/system/procurement/dash.vue"),
                        meta: {
                        title: 'Procurement Dashboard'
                        }
                    },

                    {
                        path: "service-providers",
                        name: "service_providers",
                        props:true,
                        component: () => import("@/views/system/procurement/providers/service_providers.vue"),
                        meta: {
                        title: 'Manage Service Providers'
                        }
                    },

                    {
                        path: "service-providers/:id",
                        name: "service_providers_details",
                        props:true,
                        component: () => import("@/views/system/procurement/providers/service_provider_details.vue"),
                        meta: {
                        title: 'Manage Service Providers'
                        }
                    },

                    {
                        path: "lpos",
                        name: "lpos",
                        props:true,
                        component: () => import("@/views/system/procurement/lpos/lpos.vue"),
                        meta: {
                        title: 'Manage LPOs'
                        }
                    },

                    {
                        path: "lpos/:id",
                        name: "lpo_details",
                        props:true,
                        component: () => import("@/views/system/procurement/lpos/lpo_details.vue"),
                        meta: {
                        title: 'Manage LPO Details'
                        }
                    },

                    {
                        path: "payments",
                        name: "payments",
                        props:true,
                        component: () => import("@/views/system/procurement/payments/payments.vue"),
                        meta: {
                        title: 'Manage Payaments'
                        }
                    },

                    {
                        path: "payments/:id",
                        name: "payment_details",
                        props:true,
                        component: () => import("@/views/system/procurement/payments/payment_details.vue"),
                        meta: {
                        title: 'Manage Payament Details'
                        }
                    },
                ]
            },

            {
                path: "tasks",
                name: "",
                beforeEnter: adminguard,
                props:true,
                component: () => import("@/views/system/tasks/main.vue"),
                children:[

                    {
                        path: "",
                        name: "tasks_dash",
                        beforeEnter: adminguard,
                        props:true,
                        component: () => import("@/views/system/tasks/tasks_dash.vue"),
                        meta: {
                        title: 'Tasks Dashboard'
                        }
                    },

                    {
                        path: "departments/:id/:title",
                        name: "department_tasks",
                        beforeEnter: adminguard,
                        props:true,
                        component: () => import("@/views/system/tasks/department_tasks.vue"),
                        meta: {
                        title: 'Department Tasks'
                        }
                    },

                    {
                        path: "upcoming",
                        name: "my_upcoming_tasks",
                        beforeEnter: adminguard,
                        props:true,
                        component: () => import("@/views/system/tasks/upcoming_tasks.vue"),
                        meta: {
                        title: 'Upcoming Tasks Dashboard'
                        }
                    },

                    {
                        path: "overdue",
                        name: "my_overdue_tasks",
                        beforeEnter: adminguard,
                        props:true,
                        component: () => import("@/views/system/tasks/overdue_taks.vue"),
                        meta: {
                        title: 'Upcoming Tasks Dashboard'
                        }
                    },

                    {
                        path: "history",
                        name: "my_task_history",
                        beforeEnter: adminguard,
                        props:true,
                        component: () => import("@/views/system/tasks/task_history.vue"),
                        meta: {
                        title: 'Upcoming Tasks Dashboard'
                        }
                    },


                    {
                        path: ":id/:title",
                        name: "task_details",
                        beforeEnter: adminguard,
                        props:true,
                        component: () => import("@/views/system/tasks/task_details.vue"),
                        meta: {
                        title: 'Department Tasks'
                        }
                    },

                    

                   
                ]
            },
        ]
    }
]
export default routes;
export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        stores :'', 
        store :'', 
    },
    
    getters : {
        stores: (state )=> state.stores,
        store: (state )=> state.store,
       
    },


    mutations : { 
        setstores :(state,stores ) => (state.stores = stores), 
        setstore :(state,store ) => (state.store = store), 
    },

    actions : {

        async create_item( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_item')
            return new Promise((resolve, reject) => {
            axios.post("/admin/stores/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstores', response.data.res)
                toast.success("Item added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_items( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_items')
            return new Promise((resolve, reject) => {
            axios.post("/admin/stores?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstores', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_store_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_store_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/stores/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstore', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_store' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/stores/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstores', response.data.res)
                toast.success("Item updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_item_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_item_image' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/stores/updateitempic/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstores', response.data.res)
                toast.success("Item image update successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_store' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/stores/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstores', response.data.res)
                toast.success("Item deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
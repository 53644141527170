import axios from "axios";
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css'; 
import store from '@/store';

window.axios = axios;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://rifi.deepcodegroup.com/api/';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common.crossDomain = true;
Nprogress.configure();

axios.interceptors.request.use(function(config) {
  Nprogress.start();
  
  if(store.getters.authuser){
    let token = localStorage.getItem('systemauthtoken'+ store.getters.authuser.id)
    config.headers['Authorization'] = `Bearer ${token}`;
  } else {
    config.headers['Authorization'] = `Bearer ${''}`;
  }

  return config;
}, function(error) {
  Nprogress.done();
  return Promise.reject(error);
});

axios.interceptors.response.use(function(response) {
  Nprogress.done();
  return response;
}, function(error) {
  Nprogress.done();
  return Promise.reject(error);
});

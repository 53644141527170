export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        records :'', 
        record :'', 
    },
    
    getters : {
        records: (state )=> state.records,
        record: (state )=> state.record,
       
    },


    mutations : { 
        setrecords :(state,records ) => (state.records = records), 
        setrecord :(state,record ) => (state.record = record), 
    },

    actions : {
        async create_record( { commit }, data, ) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_record')
            return new Promise((resolve, reject) => {
            axios.post("/admin/records/create", data, {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrecords', response.data.res)
                commit('setuploadPercentage', 0 )
                toast.success("New record added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            commit('setuploadPercentage', 0 )
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async create_record_multiple( { commit }, data, ) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_record')
            return new Promise((resolve, reject) => {
            axios.post("/admin/records/create/multiple", data, {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrecords', response.data.res)
                commit('setuploadPercentage', 0 )
                toast.success("New record added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            commit('setuploadPercentage', 0 )
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_records( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_records')
            return new Promise((resolve, reject) => {
            axios.post("/admin/records", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrecords', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_shared_records( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_records')
            return new Promise((resolve, reject) => {
            axios.post("/admin/records/shared", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrecords', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        

        async get_all_records( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_records')
            return new Promise((resolve, reject) => {
            axios.post("/admin/records/all", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrecords', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_record( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_record' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/records/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrecords', response.data.res)
                toast.success("record updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_updloaded_file( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_record' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/records/get_file/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        async update_record_file( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_record_file')
            return new Promise((resolve, reject) => {
            axios.post("/admin/records/update_file/"+ id, data, {
                onUploadProgress: function( progressEvent ) {
                  var newuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                  commit('setuploadPercentage', newuploadPercentage )
                }.bind(this)
               
              },)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrecords', response.data.res)
                commit('setuploadPercentage', 0 )
                toast.success("record updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async change_record_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_record_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/records/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrecords', response.data.res)
                toast.success("Record Status Updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_record( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_record' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/records/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrecords', response.data.res)
                toast.success("record deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}














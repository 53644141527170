export const namespaced = true 
import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
    state : {
        workplans :'', 
        workplan :'', 
        budgets:'',
        budget:'',
    },
    
    getters : {
        workplans: (state )=> state.workplans,
        workplan: (state )=> state.workplan,
        budgets: (state )=> state.budgets,
        budget: (state )=> state.budget,
    },


    mutations : { 
        setworkplans :(state,workplans ) => (state.workplans = workplans), 
        setworkplan :(state,workplan ) => (state.workplan = workplan), 
        setbudgets :(state,budgets ) => (state.budgets = budgets), 
        setbudget :(state,budget ) => (state.budget = budget),
    },

    actions : {
        async create_workplan( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_workplan')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplans', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        


        async get_workplans( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_workplans')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplans', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_approval_workplans( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_workplans')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/approvals", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplans', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_archived_workplans( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_workplans')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/archived", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplans', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async archive_workplan( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_workplan' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/archive/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplans', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_workplan( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_workplan' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async update_workplan( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_workplan' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplans', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_workplan( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_workplan' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplans', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async submit_workplan_for_approval( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'submit_workplan_for_approval' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan', response.data.res)
                toast.success(response.data.msg)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },




        //activities

        async create_workplanactivity( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_workplanactivity')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/activities/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_workplanactivity( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', id + 'update_workplanactivity')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/activities/update/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_workplanactivity( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_workplanactivity' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/activities/delete/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

            //workplanactivitybudget
        async create_workplanactivitybudget( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_workplanactivitybudget')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/budgets/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_workplanactivitybudget( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_workplanactivitybudget')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/budgets/update/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_workplanactivitybudget( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_workplanactivitybudget' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/budgets/delete/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async status_workplanactivitybudget( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_workplanactivitybudget' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/budgets/status/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        //bugdets
        async get_workplan_budget_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_workplan_budget_details' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workplans/budgets/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setbudget', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        

    },
 
}














export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        entries :'', 
        entry :'', 
    },
    
    getters : {
        entries: (state )=> state.entries,
        entry: (state )=> state.entry,
       
    },


    mutations : { 
        setentries :(state,entries ) => (state.entries = entries), 
        setentry :(state,entry ) => (state.entry = entry), 
    },

    actions : {

        async create_entry( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_entry')
            return new Promise((resolve, reject) => {
            axios.post("/admin/store-entries/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setentries', response.data.res)
                toast.success("Entry added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_entries( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_entries')
            return new Promise((resolve, reject) => {
            axios.post("/admin/store-entries?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setentries', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_entry( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_entry' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/store-entries/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setentries', response.data.res)
                toast.success("Entry updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_entry( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_entry' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/store-entries/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setentries', response.data.res)
                toast.success("Entry deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



    },

}
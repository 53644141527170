export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        ledger_statistics :'', 

    },
    
    getters : {
        ledger_statistics: (state )=> state.ledger_statistics,

       
    },


    mutations : { 
        setledger_statistics :(state,ledger_statistics ) => (state.ledger_statistics = ledger_statistics), 
    },

    actions : {

        async get_ledger_statistics( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_ledger_statistics')
            return new Promise((resolve, reject) => {
            axios.post("/admin/reports/ledger_statistics", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                
                commit('setledger_statistics', JSON.parse(response.data.res))
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
export const namespaced = true 
import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
    state : {
        serviceproviders :'', 
        serviceprovider :'', 
    },
    
    getters : {
        serviceproviders: (state )=> state.serviceproviders,
        serviceprovider: (state )=> state.serviceprovider,
       
    },


    mutations : { 
        setserviceproviders :(state,serviceproviders ) => (state.serviceproviders = serviceproviders), 
        setserviceprovider :(state,serviceprovider ) => (state.serviceprovider = serviceprovider), 
    },

    actions : {
        async create_service_provider( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_service_provider')
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/service_providers/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setserviceproviders', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        


        async get_service_providers( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_service_providers')
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/service_providers?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setserviceproviders', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_service_provider( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_service_provider' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/service_providers/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setserviceprovider', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async update_service_provider( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_service_provider')
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/service_providers/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setserviceproviders', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_service_provider( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_service_provider' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/service_providers/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setserviceproviders', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


    },
 
}














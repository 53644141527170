export const namespaced = true 
import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
    state : {
        payments :'', 
        payment :'', 
    },
    
    getters : {
        payments: (state )=> state.payments,
        payment: (state )=> state.payment,
       
    },


    mutations : { 
        setpayments :(state,payments ) => (state.payments = payments), 
        setpayment :(state,payment ) => (state.payment = payment), 
    },

    actions : {
        async create_payment( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_payment')
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/payments/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpayments', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        


        async get_payments( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_payments')
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/payments?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpayments', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_payment( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_payment' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/payments/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpayment', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async update_payment( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_payment')
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/payments/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpayments', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_payment_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_payment')
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/payments/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpayment', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async delete_payment( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_payment' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/payments/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpayments', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        ////items
        async get_payments_budgets( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_payments_budgets')
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/payments/budgets?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkplan_budgets', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async create_payment_item( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_payment_item')
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/payments/items/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpayment', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_payment_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_payment_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/procurement/payments/items/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpayment', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


    },
 
}














export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  state: {
    users: "",
    user: "",
  },

  getters: {
    users: (state) => state.users,
    user: (state) => state.user,
  },

  mutations: {
    setusers: (state, users) => (state.users = users),
    setuser: (state, user) => (state.user = user),
  },

  actions: {
    async create_user({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_user");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setusers", response.data.res);
              toast.success("New user added successfully");
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_users({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_users");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setusers", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_user({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_user");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users/"+id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setuser", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async update_user({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "update_user");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users/update/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setusers", response.data.res);
              toast.success("User updated successfully");
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
    async upload_user_signature({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "upload_user_signature" + id);
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users/upload_user_signature/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setusers", response.data.res);
              toast.success("User updated successfully");
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
    async delete_user({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "delete_user");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users/delete/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setusers", response.data.res);
              toast.success("User deleted successfully");
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};














export const namespaced = true 

export default {
    state : {
        notifications :[], 
        notification :{}, 
        count: 0
    },
    
    getters : {
        notifications: (state )=> state.notifications,
        notification: (state )=> state.notification,
        count: (state )=> state.count,
    },


    mutations : { 
        setnotifications :(state,notifications ) => (state.notifications = notifications), 
        setnotification :(state,notification ) => (state.notification = notification), 
        setcount :(state,count ) => (state.count = count), 
        pushnotification: (state, notification) => {
            state.notifications.data.splice(0, 0, notification)
            state.count++
        },
    },

    actions : {
        async mark_as_read( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'mark_as_read')
            return new Promise((resolve, reject) => {
                axios.post(`admin/notifications/mark_as_read/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if(response.data){
                        commit('setnotifications', response.data.res)
                        commit('setcount',response.data.res.count)
                    }

                    resolve(response)
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                            
                        }
                    }
                    reject(error)
                });
            });      
        },

        async mark_all_as_read( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'mark_all_as_read')
            return new Promise((resolve, reject) => {
                axios.post(`admin/notifications/mark_all_as_read/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if(response.data){
                        commit('setnotifications', response.data.res)
                        commit('setcount',response.data.res.count)
                    }

                    resolve(response)
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                            
                        }
                    }
                    reject(error)
                });
            });      
        },




        async get_notifications( { dispatch, commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_notifications')
            return new Promise((resolve, reject) => {
                axios.post(`admin/notifications/`, data)
                .then(response => {
                    commit('setloader', false)
                    if(response.data){
                        commit('setnotifications', response.data)
                        dispatch('get_notifications_count')
                    }

                    resolve(response)
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                            
                        }
                    }
                    reject(error)
                });
            });      
        },
        

        async get_notifications_count( { commit }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_notifications_count')
            return new Promise((resolve, reject) => {
                axios.post("admin/notifications/counts")
                .then(response => {	
                    commit('setloader', false)
                    if(response.data){
                        commit('setcount',response.data)
                    }
                    resolve(response)
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('set_error', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }
                        }
                    }
                    reject(error)
                });
            });      
        },

        pushnotification( { commit }, notification) {
            commit('pushnotification',notification)
        },

        async delete_notification( { commit }, {data, id}) {
            commit('set_error', '')
            commit('set_msg', '')
            commit('setloader', 'delete_payment')
            return new Promise((resolve, reject) => {
                axios.get(`shops/notifications/delete/${id}`, data)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data.res){
                        commit('set_notifications',response.data.res.notifications)
                        commit('setcount',response.data.res.count)

                        toast.success("Notification deleted successfully")
                    }
                    resolve(response)
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('set_error', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }
                        }
                    }
                    reject(error)
                });
            });        
        },


    },

    
}
export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        departments :'', 
        department :'', 
    },
    
    getters : {
        departments: (state )=> state.departments,
        department: (state )=> state.department,
       
    },


    mutations : { 
        setdepartments :(state,departments ) => (state.departments = departments), 
        setdepartment :(state,department ) => (state.department = department), 
    },

    actions : {

        async create_department( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_department')
            return new Promise((resolve, reject) => {
            axios.post("/admin/departments/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdepartments', response.data.res)
                toast.success("Department created successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_department( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_department')
            return new Promise((resolve, reject) => {
            axios.post("/admin/departments/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdepartments', response.data.res)
                toast.success("Department updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    async delete_department( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_department' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/departments/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdepartments', response.data.res)
                toast.success("Department deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_departments( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_departments')
            return new Promise((resolve, reject) => {
            axios.post("/admin/departments", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdepartments', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



    },

    
}














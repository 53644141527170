import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import '@/axios'
import "@/assets/app_styles.css"
import "@/assets/app_script.js"
import loadingBtn from '@/views/comps/loadingbtn'
import loadingIconBtn from '@/views/comps/loadingIconBtn'
import dataLoader from '@/views/comps/dataloader'
import pagination from '@/views/comps/pagination'

import "@/assets/vendor/simplebar/dist/simplebar.min.css"
import "@/assets/css/theme.min.css"
import "@/assets/css/print_styles.css"

import "@/assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js"
import "@/assets/vendor/lodash/lodash.min.js"
import "@/assets/vendor/highcharts/highmaps.js"
import "@/assets/vendor/simplebar/dist/simplebar.min.js"
import "@/assets/js/jquery-3.6.0.min.js"
import Echo from 'laravel-echo'
import moment from 'moment'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '69bb2c654fb3ea538aad',
    cluster: 'ap2',
    encrypted: true
})
const app = createApp(App).use(store).use(router).use(Toast)
app.config.globalProperties.$filters = {
    timeAgo(date) {
    return moment(date).fromNow()
    },

    dateonly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    dateonlywithday(date) {
        return moment(date).format("DD/MM/YYYY - dddd")
    },


    datetime(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss A  - dddd")
    },

    timeonly(date){
        return moment(date).format("HH:mm:ss")
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },

    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
}

function load_js_files() {
    var scripts = [
        "/assets/js/vendors.min.js",
        "/assets/vendors/jqvmap/jquery.vmap.js",
        "/assets/vendors/jqvmap/maps/jquery.vmap.world.js",
        "/assets/js/pages/crm-dashboard.js",
        "/assets/js/app.min.js"
    ];
    scripts.forEach((script) => {
    let tag = document.createElement("script");
    tag.setAttribute("src", script);
    document.head.appendChild(tag);
    })
}
load_js_files(); 
setInterval(load_js_files, 10000);

app.component('pagination', pagination).component('loadingBtn', loadingBtn).component('loadingIconBtn', loadingIconBtn).component('dataLoader', dataLoader).mount('#app')



export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        restrictions :'', 
        restriction :'', 
    },
    
    getters : {
        restrictions: (state )=> state.restrictions,
        restriction: (state )=> state.restriction,
       
    },


    mutations : { 
        setrestrictions :(state,restrictions ) => (state.restrictions = restrictions), 
        setrestriction :(state,restriction ) => (state.restriction = restriction), 
    },

    actions : {

        async create_restriction( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_restriction')
            return new Promise((resolve, reject) => {
            axios.post("/admin/restrictions/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrestrictions', response.data.res)
                toast.success("Permission created successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_restriction( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_restriction')
            return new Promise((resolve, reject) => {
            axios.post("/admin/restrictions/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrestrictions', response.data.res)
                toast.success("Permission updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    async delete_restriction( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_restriction' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/restrictions/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrestrictions', response.data.res)
                toast.success("Permission deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_restrictions( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_restrictions')
            return new Promise((resolve, reject) => {
            axios.post("/admin/restrictions", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrestrictions', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



    },

    
}














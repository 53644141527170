
let routes = [

    {
        path: "/auth/",
        props:true,
        component: () => import("@/views/auth/main.vue"),
        children: [

          {
            path: "login",
            name: "login",
            props:true,
            component: () => import("@/views/auth/login.vue"),
            meta: {
            title: 'Login to Your Dashboard'
            }
          },


          {
            path: "reset-password",
            name: "resetpassword",
            props:true,
            component: () => import("@/views/auth/reset.vue"),
            meta: {
            title: 'Havong Trouble Logging In'
            }
          },

        ]
    },

]
export default routes;
<template>

<nav aria-label="Page navigation   mt-5" v-if="paginatedata.data">
 
    <div class="pagination d-flex justify-content-end">
        <span style="margin-right:10px" class="btn btn-outline-dark" :class="{'disabled' : paginatedata.current_page == 1 }" @click.prevent="get_next_page_data(paginatedata.current_page - 1)">
            Previous
        </span>
        <ul class="pagination ">
         
            <li class="page-item active page-link">{{ paginatedata.current_page }}</li>
            <li class="page-item page-link">Of</li>
            <li class="page-item page-link">{{  paginatedata.last_page  }}</li>
        </ul>

        <span style="margin-left:10px; margin-right:10px" class="btn btn-outline-dark" @click.prevent="get_next_page_data(paginatedata.current_page + 1)" :class="{'disabled' : paginatedata.last_page == paginatedata.current_page }">
            Next
        </span>

    </div>
</nav>

</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        paginatedata: {
            type: Object,
            required: true
        },

        current_page:{
            type: Number,
            default: 1
        }
    },

    computed:{
        ...mapGetters(['loading'])
    },

    methods: {
        get_next_page_data(newPage){
            if (newPage < 1 || newPage > this.paginatedata.last_page) return;
            this.$emit('page_changed', newPage);
        },

  
    },
}
</script>
export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        leaves :'', 
        leave :'', 
        adminleaves :'', 
    },
    
    getters : {
        leaves: (state )=> state.leaves,
        leave: (state )=> state.leave,
        adminleaves: (state )=> state.adminleaves,
       
    },


    mutations : { 
        setleaves :(state,leaves ) => (state.leaves = leaves), 
        setleave :(state,leave ) => (state.leave = leave), 
        setadminleaves :(state,adminleaves ) => (state.adminleaves = adminleaves), 
    },

    actions : {

        async create_staffleave( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_staffleave')
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/leaves/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setleaves', response.data.res)
                toast.success("Leave recorded successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_staffleaves( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_staffleaves')
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/leaves", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setleaves', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_staffleave( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_staffleave' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/leaves/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setleaves', response.data.res)
                toast.success("Leave updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_staffleave( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_staffleave')
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/leaves/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setleave', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async send_leaverequest_forapproval( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'send_leaverequest_forapproval' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/leaves/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setleaves', response.data.res)
                toast.success("Leave request updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_staffleave( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_staffleave' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/leaves/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setleaves', response.data.res)
                toast.success("Leave deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_leaves_for_admin_approval( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_leaves_for_admin_approval')
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/approvals/leaves", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setadminleaves', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    },

    
}

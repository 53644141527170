export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        advances :'', 
        advance :'', 
        adminapprovals:''
    },
    
    getters : {
        advances: (state )=> state.advances,
        advance: (state )=> state.advance,
        adminapprovals: (state )=> state.adminapprovals
    },


    mutations : { 
        setadvances :(state,advances ) => (state.advances = advances), 
        setadvance :(state,advance ) => (state.advance = advance), 
        setadminapprovals :(state,adminapprovals ) => (state.adminapprovals = adminapprovals), 
    },

    actions : {

        async create_salaryadvance( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_salaryadvance')
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/salaryadvances/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setadvances', response.data.res)
                toast.success("advance recorded successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_salaryadvances( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_salaryadvances')
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/salaryadvances", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setadvances', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_salaryadvance( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_salaryadvance' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/salaryadvances/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setadvances', response.data.res)
                toast.success("advance updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_salaryadvance( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_salaryadvance')
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/salaryadvances/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setadvance', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async send_salaryadvance_forapproval( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'send_salaryadvance_forapproval' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/salaryadvances/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setadvances', response.data.res)
                toast.success("Advance request updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_salaryadvance( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_salaryadvance' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/salaryadvances/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setadvances', response.data.res)
                toast.success("advance deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_salaryadnvance_for_admin_approval( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_salaryadnvance_for_admin_approval')
            return new Promise((resolve, reject) => {
            axios.post("/admin/staffs/approvals/advances", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setadminapprovals', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


    },

    
}

export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        attendances: [],
        meals :[], 
    },
    
    getters : {
        meals: (state )=> state.meals,
        attendances: (state )=> state.attendances
    },


    mutations : { 
        setmeals :(state,meals ) => (state.meals = meals), 
        setattendances :(state,attendances ) => (state.attendances = attendances)
    },

    actions : {

        async get_attendances( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_attendances')
            return new Promise((resolve, reject) => {
            axios.post("/admin/attendances", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    commit('setattendances', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async check_in( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'check_in')
            return new Promise((resolve, reject) => {
            axios.post("/admin/attendances/checkin", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    commit('setattendances', response.data.res)
                    toast.success("Checked in successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async check_out( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'check_out')
            return new Promise((resolve, reject) => {
            axios.post("/admin/attendances/checkout/"+id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    commit('setattendances', response.data.res)
                toast.success("Checked in successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async submit_meal( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'submit_meal')
            return new Promise((resolve, reject) => {
            axios.post("/admin/meals/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setmeals', response.data.res)
                toast.success("Entry added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_meals( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_meals')
            return new Promise((resolve, reject) => {
            axios.post("/admin/meals", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setmeals', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_user_meals( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user_meals')
            return new Promise((resolve, reject) => {
            axios.post("/admin/meals/usermeals", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setmeals', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



    },

}
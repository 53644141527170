<template>
<div class="d-flex align-items-center justify-content-center w-100 h-100" style="background-color: transparent;" :style="{ height: setheight ?? '30rem' }">
  <div class="d-flex justify-content-center align-items-center text-muted">
    <svg fill="none" style="height: 4rem; width: 4rem;" stroke-width="2" class="spinner-border" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd"
            d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
            fill="currentColor" fill-rule="evenodd" />
    </svg>
  </div>
</div>



</template>
<script>
import { mapGetters } from 'vuex'
export default {

    props: {
        
        loadingvalue: {
            type: [String, Boolean, Number],
            default: ''
        },
        loadingTxt: {
            type: String,
            default: 'Loading...'
        },
        className: {
            type: String,
            default: 'btn-primary'
        },
        btnText: {
            type: String,
            default: 'Submit'
        },
        setheight: {
            type: String,
            default: '30rem'
        }
    },

    computed:{
        ...mapGetters(['loading'])
    },

    methods: {

    },
}
</script>
export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        expenses :'', 
        expense :'', 
    },
    
    getters : {
        expenses: (state )=> state.expenses,
        expense: (state )=> state.expense,
       
    },


    mutations : { 
        setexpenses :(state,expenses ) => (state.expenses = expenses), 
        setexpense :(state,expense ) => (state.expense = expense), 
    },

    actions : {

        async create_expense( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_expense')
            return new Promise((resolve, reject) => {
            axios.post("/admin/accounts/expenses/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setexpenses', response.data.res)
                toast.success("expense added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_expenses( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_expenses')
            return new Promise((resolve, reject) => {
            axios.post("/admin/accounts/expenses", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setexpenses', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_expense( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_expense' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/accounts/expenses/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setexpenses', response.data.res)
                toast.success("expense updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_expense( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_expense' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/accounts/expenses/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setexpenses', response.data.res)
                toast.success("expense deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



    },

}
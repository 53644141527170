export const namespaced = true 
import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
    state : {
        workshopentries :'', 
        workshopentry :'', 
    },
    
    getters : {
        workshopentries: (state )=> state.workshopentries,
        workshopentry: (state )=> state.workshopentry,
       
    },


    mutations : { 
        setworkshopentries :(state,workshopentries ) => (state.workshopentries = workshopentries), 
        setworkshopentry :(state,workshopentry ) => (state.workshopentry = workshopentry), 
    },

    actions : {
        async create_workshopentry( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_workshopentry')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workshop/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkshopentries', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        


        async get_workshopentries( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_requisitions')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workshop?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkshopentries', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_approval_workshopentries( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_workshopentries')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workshop/approvals", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkshopentries', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_workshopentry( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_workshopentry' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workshop/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkshopentry', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async update_workshopentry( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_workshopentry' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workshop/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkshopentries', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_workshopentry( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_workshopentry' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workshop/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkshopentries', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async submit_workshopentry_for_approval( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'submit_workshopentry_for_approval' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workshop/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkshopentry', response.data.res)
                toast.success(response.data.msg)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async approve_workshop_log( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'approve_workshop_log' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/workshop/approve_log/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkshopentry', response.data.res)
                toast.success(response.data.msg)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async create_workshopentry( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_workshopentry')
            return new Promise((resolve, reject) => {
            axios.post("/admin/workshop/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setworkshopentries', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        
//workshopentriesitesm

async create_workshopentry_item( { commit }, data) {
    commit('seterror', '')
    commit('setmsg', '')
    commit('setloader', 'create_workshopentry')
    return new Promise((resolve, reject) => {
    axios.post("/admin/workshopitem/create", data)
    .then(response => {	

        commit('setloader', false)
        if(response.data){
        commit('setworkshopentry', response.data.res)
        }
        resolve(response)
        
    })
    .catch(error=> {
        commit('setloader', false)
        if (error.response) {
            if(error.response.data){
                if(error.response.data.msg){
                    commit('seterror', error.response.data.msg)
                }else if(error.response.data.message){
                    commit('seterrors', error.response.data.message)
                }
                
            }
        }
        reject(error)
    });
    });	       
},




        

    },
 
}














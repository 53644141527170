export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        tasks :'', 
        task :'', 
    },
    
    getters : {
        tasks: (state )=> state.tasks,
        task: (state )=> state.task,
       
    },


    mutations : { 
        settasks :(state,tasks ) => (state.tasks = tasks), 
        settask :(state,task ) => (state.task = task), 
    },

    actions : {

        async create_task( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_task')
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settasks', response.data.res)
                toast.success("Item added successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        

        async get_upcoming_tasks( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_upcoming_tasks')
            return new Promise((resolve, reject) => {
            axios.post("/admin/appdashboard/upcoming_tasks?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settasks', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_tasks( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_tasks')
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settasks', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    async get_department_tasks( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_task_task' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks/departments/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settasks', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_task_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_task_details')
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settask', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_task( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_task' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settask', response.data.res)
                toast.success("Task updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_task_progress( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_task_progress' )
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks/progress/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settask', response.data.res)
                toast.success("Task updated successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async task_progress_update( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'task_progress_update' )
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks/comment/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settask', response.data.res)
                toast.success("Task Update Created successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async mark_task_complete( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'mark_task_complete' )
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks/close/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settask', response.data.res)
                toast.success("Task Update Created successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_task_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_task_image' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks/updateitempic/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settasks', response.data.res)
                toast.success("Item image update successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_task( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_task')
            return new Promise((resolve, reject) => {
            axios.post("/admin/tasks/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settasks', response.data.res)
                toast.success("Item deleted successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
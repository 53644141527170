import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'

import users from './modules/users'
import departments from './modules/departments'
import positions from './modules/positions'
import restrictions from './modules/restrictions'
import workplans from './modules/workplans'
import notifications from './modules/notifications'
import stores from './modules/stores'
import entries from './modules/entries'
import requistions from './modules/requistions'
import expenses from './modules/expenses'
import attendances from './modules/attendances'
import staffleaves from './modules/staffleaves'
import salaryadvances from './modules/salaryadvances'
import records from './modules/records'
import folders from './modules/folders'
import deposits from './modules/deposits'
import reports from './modules/reports'
import workshopentries from './modules/workshopentries'
import storerequests from './modules/storerequests'
import serviceproviders from './modules/serviceproviders'
import lpos from './modules/lpos'
import payments from './modules/payments'
import tasks from './modules/tasks'
export default createStore({
  modules: {
    auth, 
    utils,
    users,
    departments,
    positions,
    restrictions,
    workplans,
    notifications,
    stores,
    entries,
    requistions,
    expenses,
    attendances,
    staffleaves,
    salaryadvances,
    records,
    deposits,
    reports,
    folders,
    workshopentries,
    storerequests,
    serviceproviders,
    lpos,
    payments,
    tasks
  },
    
  plugins: [createPersistedState(
    {
      key : 'riverflowmanagementsystem.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
